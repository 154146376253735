//trucks
export const LOAD_TRUCKS = 'LOAD_TRUCKS';
export const LOADING_TRUCKS = 'LOADING_TRUCKS';
export const DELETE_TRUCK = 'DELETE_TRUCK';
export const ADD_TRUCK = 'ADD_TRUCK';
export const GET_TRUCK = 'GET_TRUCK';
export const EDIT_TRUCK = 'EDIT_TRUCK';
//truck_maintenances
export const LOAD_TRUCK_MAINTENANCES = 'LOAD_TRUCK_MAINTENANCES';
export const LOADING_TRUCK_MAINTENANCES = 'LOADING_TRUCK_MAINTENANCES';
export const DELETE_TRUCK_MAINTENANCE = 'DELETE_TRUCK_MAINTENANCE';
export const ADD_TRUCK_MAINTENANCE = 'ADD_TRUCK_MAINTENANCE';
//errors
export const ERROR_ADDING_TRUCK = 'ERROR_ADDING_TRUCK';
export const ERROR_EDiTING_TRUCK = 'ERROR_EDiTING_TRUCK';
export const RESET_ERRORS = 'RESET_ERRORS';
export const CLEAN_MODIFIED = 'CLEAN_MODIFIED';
