//trucks
export const LOAD_INVOICES = 'LOAD_INVOICES';
export const LOADING_INVOICES = 'LOADING_INVOICES';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const ADD_INVOICE = 'ADD_INVOICE';
export const GET_INVOICE = 'GET_INVOICE';
export const EDIT_INVOICE = 'EDIT_INVOICE';
export const SEND_INVOICE = 'SEND_INVOICE';
export const GET_ALL_DATA_INVOICE = 'GET_ALL_DATA_INVOICE';
//errors
export const ERROR_ADDING_INVOICE = 'ERROR_ADDING_INVOICE';
export const ERROR_SENDING_INVOICE = 'ERROR_SENDING_INVOICE';
export const ERROR_EDiTING_INVOICE = 'ERROR_EDiTING_INVOICE';
export const RESET_ERRORS_INVOICES = 'RESET_ERRORS_INVOICES';
export const CLEAN_MODIFIED_INVOICES = 'CLEAN_MODIFIED_INVOICES';
