import dashboard from './dashboard';
import section1 from './section1';
import section2 from './section2';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, section1, section2]
};

export default menuItems;
