//customers
export const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS';
export const LOADING_CUSTOMERS = 'LOADING_CUSTOMERS';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const ERROR_ADDING_CUSTOMER = 'ERROR_ADDING_CUSTOMER';
export const ERROR_EDiTING_CUSTOMER = 'ERROR_EDiTING_CUSTOMER';
export const RESET_ERRORS = 'RESET_ERRORS';
export const CLEAN_MODIFIED = 'CLEAN_MODIFIED';
