//customers
export const LOAD_SERVICES = 'LOAD_SERVICES';
export const LOADING_SERVICES = 'LOADING_SERVICES';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const ADD_SERVICE = 'ADD_SERVICE';
export const GET_SERVICE = 'GET_SERVICE';
export const EDIT_SERVICE = 'EDIT_SERVICE';
export const ERROR_ADDING_SERVICE = 'ERROR_ADDING_SERVICE';
export const ERROR_EDiTING_SERVICE = 'ERROR_EDiTING_SERVICE';
export const RESET_ERRORS = 'RESET_ERRORS';
export const CLEAN_MODIFIED = 'CLEAN_MODIFIED';
